import mHeader from './header';
import mFooter from './footer';
import appMain from './appMain';
export default {
  name: "",
  components: {
    mHeader,
    mFooter,
    appMain
  },

  mounted() {
    const script = document.createElement('script');
    script.src = 'https://webchat.7moor.com/javascripts/7moorInit.js?accessId=c88c0b60-1a49-11ee-8bb2-a33e89ba4696&autoShow=true&language=ZHCN';
    document.head.appendChild(script);
  }

};