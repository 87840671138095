var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    ref: "footer",
    attrs: {
      id: "footer"
    }
  }, [_c("div", {
    staticClass: "footerTop"
  }, [_c("div", {
    staticClass: "fleft"
  }, [_c("div", {
    staticClass: "flogo"
  }, [_c("img", {
    ref: "logo_img",
    attrs: {
      src: require("../assets/footer_logos.png"),
      alt: "诺辉健康"
    }
  })])]), _c("div", {
    ref: "society_logos",
    staticClass: "fright"
  }, [_vm.language == 1 || _vm.language == 3 ? _c("ul", {
    staticClass: "fright_bootom clearfix"
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _c("li", {
    staticClass: "wBox",
    on: {
      click: function ($event) {
        return _vm.jumpTo("https://weibo.com/u/6029847285");
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/xl.png")
    }
  })]), _vm._m(3), _c("li", {
    staticClass: "title"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.society_account[parseInt(_vm.language) - 1]))])])]) : _vm._e()])]), _c("div", [_c("div", {
    ref: "left_text",
    staticClass: "footerMenu"
  }, [_c("div", {
    staticClass: "menuLeft"
  }, [_vm.language == 1 ? _c("div", {
    staticClass: "service"
  }, [_c("p", {
    staticClass: "w"
  }, [_vm._v("客服服务热线")]), _c("p", {
    staticClass: "w"
  }, [_vm._v("400 - 826 - 2300")]), _c("p", {
    staticClass: "w"
  }, [_vm._v("客服服务时间")]), _vm._m(4)]) : _vm._e(), _vm.language == 2 ? _c("div", {
    staticClass: "service"
  }, [_c("p", {
    staticClass: "w"
  }, [_vm._v("Customer Service Phone")]), _c("p", {
    staticClass: "w"
  }, [_vm._v("400 - 826 - 2300")]), _c("p", {
    staticClass: "w"
  }, [_vm._v("Customer Service Hours")]), _vm._m(5)]) : _vm._e(), _vm.language == 3 ? _c("div", {
    staticClass: "service"
  }, [_c("p", {
    staticClass: "w"
  }, [_vm._v("客服服務熱線")]), _c("p", {
    staticClass: "w"
  }, [_vm._v("400 - 826 - 2300")]), _c("p", {
    staticClass: "w"
  }, [_vm._v("客服服務時間")]), _vm._m(6)]) : _vm._e()]), _c("div", {
    staticClass: "menuRight"
  }, [_c("div", {
    staticClass: "menu"
  }, _vm._l(_vm.list, function (items, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "bigmenu"
    }, [_c("a", {
      on: {
        click: function ($event) {
          return _vm.changeRoute(items);
        }
      }
    }, [_vm._v(_vm._s(_vm.language == 1 ? items.name : _vm.language == 2 ? items.nameEn : items.nameTr))])])]);
  }), 0), _c("div", {
    staticClass: "sub-menu"
  }, [_c("div", [_c("router-link", {
    attrs: {
      to: {
        path: "/service"
      }
    }
  }, [_c("span", {
    staticClass: "font-white"
  }, [_vm._v(_vm._s(_vm.mapText.terms_of_service[parseInt(_vm.language) - 1]))])])], 1), _c("div", [_c("router-link", {
    attrs: {
      to: {
        path: "/property"
      }
    }
  }, [_c("span", {
    staticClass: "font-white"
  }, [_vm._v(_vm._s(_vm.mapText.intellectual_property[parseInt(_vm.language) - 1]))])])], 1), _c("div", [_c("router-link", {
    attrs: {
      to: {
        path: "/privacy"
      }
    }
  }, [_c("span", {
    staticClass: "font-white"
  }, [_vm._v(_vm._s(_vm.mapText.privacy_statement[parseInt(_vm.language) - 1]))])])], 1)])])]), _c("div", {
    ref: "right_text",
    staticClass: "copyright"
  }, [_vm.language == 1 ? _c("div", {
    staticClass: "copyrightMain clearfix"
  }, [_vm._m(7)]) : _vm._e(), _vm.language == 2 ? _c("div", {
    staticClass: "copyrightMain clearfix"
  }, [_c("p", {
    staticClass: "p_en font-white"
  }, [_vm._v("Copyright ©2015-2022 New Horizon Health")])]) : _vm._e(), _vm.language == 3 ? _c("div", {
    staticClass: "copyrightMain clearfix"
  }, [_vm._m(8)]) : _vm._e()])])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("div", {
    staticClass: "kBox"
  }, [_c("img", {
    staticStyle: {
      width: "10em",
      float: "left"
    },
    attrs: {
      src: require("../assets/kuaishouERcode.jpg")
    }
  })]), _c("img", {
    attrs: {
      src: require("../assets/kuaishou.png")
    }
  })]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("div", {
    staticClass: "dBox"
  }, [_c("img", {
    staticStyle: {
      width: "10em",
      float: "left"
    },
    attrs: {
      src: require("../assets/douyinERcode.jpg")
    }
  })]), _c("img", {
    attrs: {
      src: require("../assets/douyin.png")
    }
  })]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("div", {
    staticClass: "xBox"
  }, [_c("img", {
    staticStyle: {
      width: "10em",
      float: "left"
    },
    attrs: {
      src: require("../assets/xiaohongshuERcode.jpg")
    }
  })]), _c("img", {
    attrs: {
      src: require("../assets/xhs.png")
    }
  })]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("li", [_c("div", {
    staticClass: "iBox"
  }, [_c("img", {
    staticStyle: {
      width: "10em",
      float: "left"
    },
    attrs: {
      src: require("../assets/dy.jpg")
    }
  }), _c("img", {
    staticStyle: {
      width: "10em",
      float: "left"
    },
    attrs: {
      src: require("../assets/fw.jpg")
    }
  }), _c("img", {
    staticStyle: {
      width: "10em",
      float: "left"
    },
    attrs: {
      src: require("../assets/sp.jpg")
    }
  })]), _c("img", {
    attrs: {
      src: require("../assets/weinxin.png")
    }
  })]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("p", {
    staticClass: "w"
  }, [_vm._v("9:00-18:00"), _c("span", {
    staticClass: "w"
  }, [_vm._v("（工作时间）")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("p", {
    staticClass: "w"
  }, [_vm._v("9:00-18:00"), _c("span", {
    staticClass: "w"
  }, [_vm._v("（working day）")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("p", {
    staticClass: "w"
  }, [_vm._v("9:00-18:00"), _c("span", {
    staticClass: "w"
  }, [_vm._v("（工作時間）")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "copyright_left"
  }, [_c("p", {
    staticClass: "font-white"
  }, [_vm._v("版权所有Copyright ©2015-2022  杭州诺辉健康科技有限公司")]), _c("p", {
    staticClass: "font-white"
  }, [_vm._v("杭州市滨江区长河街道江二路400号和瑞国际科技广场S1-1层")]), _c("p", {
    staticClass: "font-white"
  }, [_vm._v("医疗器械生产许可证:浙药监械生产许20180014号 | 医疗器械生产备案凭证:浙杭药监械生产备20170040号")]), _c("p", {
    staticClass: "font-white"
  }, [_c("a", {
    attrs: {
      href: "https://beian.miit.gov.cn"
    }
  }, [_vm._v("备案号:浙ICP备14001025号-2")]), _vm._v(" | "), _c("a", {
    attrs: {
      href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802003705"
    }
  }, [_c("img", {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      src: require("../assets/ga.png")
    }
  }), _vm._v("公安备案号:33010802003705号")])])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "copyright_left"
  }, [_c("p", {
    staticClass: "font-white"
  }, [_vm._v("版權所有Copyright © 2015-2022  杭州諾輝健康科技有限公司")]), _c("p", {
    staticClass: "font-white"
  }, [_vm._v("杭州市濱江區長河街道江二路400號和瑞國際科技廣場S1-1層")]), _c("p", {
    staticClass: "font-white"
  }, [_vm._v("醫療器械生產許可證：浙藥監械生產許20180014號 | 醫療器械生產備案憑證：浙杭藥監械生產備20170040號")]), _c("p", {
    staticClass: "font-white"
  }, [_c("a", {
    attrs: {
      href: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802003705"
    }
  }, [_c("img", {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      src: require("../assets/ga.png")
    }
  }), _vm._v("警察備案號：33010802003705號")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };