export default {
  name: 'nx-lang-select',
  computed: {
    language() {
      return this.$store.getters.language;
    }

  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('setLanguage', lang);
    }

  }
};