import Vue from 'vue'; //import Vue from "../node_modules/vue/dist/vue.js"; 意思是：因为main.js是在src文件中，所以../向前一级相对目录查找node_modules，再依次寻找后面的文件。文件找到了，那么文件内是否存在Vue呢？事实证明，Vue是存在于vue.js中的。   

import App from './App.vue'; //import App from './App.vue';顾名思义，这句代码的意思就是引入我们写好的.vue文件。（.vue文件是vue框架的单文件组件。）

import VueRouter from 'vue-router';
import router from './router'; //import router from './route.js';顾名思义，这句代码的意思就是引入和main.js同级目录下的route.js文件。

import store from './store';
import Vuex from 'vuex';
Vue.use(Vuex);
import 'lib-flexible/flexible.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import '../node_modules/swiper/dist/css/swiper.min.css';
import '../node_modules/swiper/dist/js/swiper.min';
import './permission';
Vue.config.productionTip = false;
Vue.use(VueRouter);
import i18n from './lang';
import request from '@/utils/request';
Vue.prototype.$http = request;
import api from './api/api';
Vue.prototype.$api = api;
import Directive from './directive/index';
Vue.use(Directive);
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'http://api.map.baidu.com/api?v=2.0&ak=17ceb69d0008b9184f7261d2e8163d7c&s=1'
});
import 'hover.css';
import 'animate.css';
import './utils/viewport';
import './styles/mobile.scss';
import './styles/pc.scss';
import './styles/pcNew.scss';
import './styles/mobileNew.scss'; // 号数过滤器

Vue.filter('dateFilter', function (status) {
  console.log(status);
  let nowDate = new Date(status.split('.')[0].replace(/\-/g, '/'));
  return nowDate.getDate();
}); // 月份过滤器

Vue.filter('monthFilter', function (status) {
  let nowDate = new Date(status.split('.')[0].replace(/\-/g, '/'));
  return nowDate.getFullYear() + '.' + (nowDate.getMonth() + 1);
}); // 时间过滤器

Vue.filter('dayFilter', function (status) {
  let nowDate = new Date(status.split('.')[0].replace(/\-/g, '/'));
  return nowDate.getFullYear() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getDate();
});
const language = navigator.systemLanguange || navigator.language;
let languageType = 1;

if (language === 'en') {
  languageType = 2;
} else if (language === 'zh-CN' || language === 'zh') {
  languageType = 1;
} else {
  languageType = 3;
}

localStorage.setItem('language', languageType);
console.log('🚀 ~ language', language);
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
});