var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    class: _vm.indexMenu == "/index" ? "indexCss" : "",
    attrs: {
      id: "header"
    }
  }, [_c("div", {
    staticClass: "header space-between",
    class: _vm.headerScroll ? "headerScroll" : ""
  }, [_c("div", {
    staticClass: "logo",
    on: {
      click: _vm.indexClick
    }
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    attrs: {
      src: require("../assets/afc4ae365e3c5d48de87b5eab814359.png"),
      alt: "诺辉健康"
    }
  }), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }],
    attrs: {
      src: require("../assets/f89ffe3374a8b4ede2bb46b212407c3.png"),
      alt: "诺辉健康"
    }
  })]), _c("transition", {
    attrs: {
      name: "draw"
    }
  }, [_c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device !== "mobile" || _vm.show_list,
      expression: "device!=='mobile' || show_list"
    }],
    staticClass: "headerMenu clearfix j_PopupMenu",
    class: _vm.language == 2 ? "headerMenuEn" : ""
  }, _vm._l(_vm.list, function (items, index) {
    return !(_vm.language == 2 && items.value == "corporate_culture") ? _c("li", {
      key: index,
      class: _vm.$route.path == items.path ? "active menuli" : "menuli"
    }, [_c("a", {
      staticClass: "menuItem",
      class: {
        "el-icon-arrow-right": items.children,
        "bigger-font": (_vm.language == 1 || _vm.language == 3) && (items.value == "user_story" || items.value == "news_activity")
      },
      on: {
        click: function ($event) {
          return _vm.changeRoute(items);
        }
      }
    }, [_c("img", {
      staticClass: "header-title-pic",
      class: {
        show: _vm.$route.path == items.path && _vm.device !== "mobile"
      },
      attrs: {
        src: require("../assets/header_logo.svg"),
        alt: ""
      }
    }), _vm._v(" " + _vm._s(_vm.language == 1 ? items.name : _vm.language == 2 ? items.nameEn : items.nameTr) + " ")]), items.children ? _c("dl", {
      staticClass: "headerSecMenu clearfix"
    }, [_c("div", _vm._l(items.children, function (item, i) {
      return _c("dd", {
        key: i,
        class: _vm.activeMenu == item.value ? "active" : ""
      }, [_c("a", {
        attrs: {
          href: _vm.changeRouteChild(items, item)
        }
      }, [_vm._v(" " + _vm._s(_vm.language == 1 ? item.name : _vm.language == 2 ? item.nameEn : item.nameTr) + " ")]), item.children ? _c("div", {
        staticClass: "third-menu"
      }, _vm._l(item.children, function (item_third, index_third) {
        return _c("div", {
          key: index_third,
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null;
              return _vm.changeRouteChild(item, item_third);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.language == 1 ? item_third.name : _vm.language == 2 ? item_third.nameEn : item_third.nameTr) + " ")]);
      }), 0) : _vm._e()]);
    }), 0)]) : _vm._e()]) : _vm._e();
  }), 0)]), _c("div", {
    staticClass: "login-btn-top"
  }, [_c("a", {
    class: _vm.language == 1 ? "active" : "",
    attrs: {
      title: "简"
    },
    on: {
      click: function ($event) {
        return _vm.setLanguage(1);
      }
    }
  }, [_vm._v(" 简 ")]), _c("span", {
    staticClass: "vertical-line"
  }, [_vm._v("|")]), _c("a", {
    class: _vm.language == 2 ? "active" : "",
    attrs: {
      title: "EN"
    },
    on: {
      click: function ($event) {
        return _vm.setLanguage(2);
      }
    }
  }, [_vm._v(" EN ")]), _c("span", {
    staticClass: "vertical-line"
  }, [_vm._v("|")]), _c("a", {
    class: _vm.language == 3 ? "active" : "",
    attrs: {
      title: "繁"
    },
    on: {
      click: function ($event) {
        return _vm.setLanguage(3);
      }
    }
  }, [_vm._v(" 繁 ")])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device == 'mobile'"
    }],
    staticClass: "h_left j_PopupMenuParent",
    staticStyle: {
      "margin-right": "15px",
      color: "#fff",
      "font-size": "18px"
    }
  }, [_c("i", {
    staticClass: "el-icon-s-operation",
    on: {
      click: function ($event) {
        _vm.drawer = true;
      }
    }
  })])], 1), _c("el-drawer", {
    attrs: {
      title: "导航",
      visible: _vm.drawer,
      direction: "rtl",
      size: "80%",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.drawer = $event;
      }
    }
  }, [_c("el-menu", {
    staticClass: "el-menu-vertical-demo",
    attrs: {
      "default-active": _vm.activeMenu,
      collapse: false,
      "text-color": "#000",
      "active-text-color": "#000",
      id: "navbar"
    }
  }, [_c("el-submenu", {
    attrs: {
      index: "1"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    staticClass: "el-icon-office-building"
  }), _c("span", {
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/about_company");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "关于我们" : _vm.language == 2 ? "ABOUT US" : "關於諾輝"))])]), _c("el-menu-item", {
    attrs: {
      index: "1-1"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/about_company", "about_company");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "公司介绍" : _vm.language == 2 ? "ABOUT US" : "關於諾輝"))]), _c("el-menu-item", {
    attrs: {
      index: "1-2"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/about_company", "development_history");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "发展历史" : _vm.language == 2 ? "History" : "發展歷史"))]), _c("el-menu-item", {
    attrs: {
      index: "1-3"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/about_company", "management_layer");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "管理团队" : _vm.language == 2 ? "Leadership" : "管理團隊"))]), _c("el-menu-item", {
    attrs: {
      index: "1-4"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/about_company", "honors_qualifications");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "荣誉资质" : _vm.language == 2 ? "Qualification Honor" : "榮譽資質"))]), _c("el-menu-item", {
    attrs: {
      index: "1-5"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/about_company", "contact_us");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "联系我们" : _vm.language == 2 ? "Contact Us" : "聯繫我們"))]), _c("el-menu-item", {
    attrs: {
      index: "1-6"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/about_company", "honest");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "社会监督" : _vm.language == 2 ? "Social Hupervision" : "社會監督"))])], 2), _c("el-submenu", {
    attrs: {
      index: "2"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    staticClass: "el-icon-pie-chart"
  }), _c("span", {
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/product");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "产品体系" : _vm.language == 2 ? "OUR TESTS" : "產品體系"))])]), _c("el-menu-item", {
    attrs: {
      index: "2-1"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/product_detail", "常卫清®");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "常卫清®" : _vm.language == 2 ? "ColoClear®" : "常衛清®"))]), _c("el-menu-item", {
    attrs: {
      index: "2-2"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/product_detail", "噗噗管®");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "噗噗管®" : _vm.language == 2 ? "Pupu Tube®" : "噗噗管®"))]), _c("el-menu-item", {
    attrs: {
      index: "2-3"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/product_detail", "幽幽管®");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "幽幽管®" : _vm.language == 2 ? "UU Tube®" : "幽幽管®"))]), _c("el-menu-item", {
    attrs: {
      index: "2-4"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/product_detail", "宫证清™");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "宫证清™(在研)" : _vm.language == 2 ? "CerviClear®(Pipeline)" : "宫证清™(在研)"))]), _c("el-menu-item", {
    attrs: {
      index: "2-5"
    },
    on: {
      click: function ($event) {
        _vm.mobileChangeRoute("/product_detail", "中国泛癌种早筛早诊PANDA项目(在研)");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "中国泛癌种早筛早诊PANDA项目(在研)" : _vm.language == 2 ? "China MCED PANDA Project(Pipeline)" : "中國泛癌種早篩早診PANDA項目(在研)"))])], 2), _c("el-menu-item", {
    attrs: {
      index: "3"
    }
  }, [_c("i", {
    staticClass: "el-icon-video-camera"
  }), _c("span", {
    attrs: {
      slot: "title"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/user_story");
      }
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.language == 1 ? "用户故事" : _vm.language == 2 ? "PATIENT STORIES" : "用戶故事"))])]), _c("el-menu-item", {
    attrs: {
      index: "4"
    }
  }, [_c("i", {
    staticClass: "el-icon-chat-line-square"
  }), _c("span", {
    attrs: {
      slot: "title"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/news_activity");
      }
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.language == 1 ? "新闻中心" : _vm.language == 2 ? "NEWS ROOM" : "新聞中心"))])]), _vm.language != 2 ? _c("el-menu-item", {
    attrs: {
      index: "5"
    }
  }, [_c("i", {
    staticClass: "el-icon-help"
  }), _c("span", {
    attrs: {
      slot: "title"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/corporate_culture");
      }
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.language == 1 ? "企业文化" : _vm.language == 2 ? "Corporate Culture" : "企業文化"))])]) : _vm._e(), _c("el-submenu", {
    attrs: {
      index: "6"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("i", {
    staticClass: "el-icon-user"
  }), _c("span", {
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/companyInformation");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "投资者关系" : _vm.language == 2 ? "INVESTORS RELATIONS" : "投資者關係"))])]), _c("el-menu-item", {
    attrs: {
      index: "6-1"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/companyInformation");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "公司资料" : _vm.language == 2 ? "Corporate Information" : "公司資料"))]), _c("el-menu-item", {
    attrs: {
      index: "6-2"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/event");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "投资者日历" : _vm.language == 2 ? "Events Calendar" : "活動日曆"))]), _c("el-menu-item", {
    attrs: {
      index: "6-2"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/monthlyReport");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "投资者月报" : _vm.language == 2 ? "Monthly Reportr" : "投資者月報"))]), _c("el-menu-item", {
    attrs: {
      index: "6-3"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/offering");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "招股文件" : _vm.language == 2 ? "Listing Documents" : "招股文件"))]), _c("el-menu-item", {
    attrs: {
      index: "6-4"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/notice");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "公告及通函" : _vm.language == 2 ? "Announcements & Notices" : "公告及通函"))]), _c("el-menu-item", {
    attrs: {
      index: "6-5"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/governance");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "企业管治" : _vm.language == 2 ? "Corporate Governance" : "企業管治"))]), _c("el-menu-item", {
    attrs: {
      index: "6-6"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/stock");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "股票信息" : _vm.language == 2 ? "Stock Information" : "股票資訊"))]), _c("el-menu-item", {
    attrs: {
      index: "6-7"
    },
    on: {
      click: function ($event) {
        return _vm.mobileChangeRoute("/contact");
      }
    }
  }, [_vm._v(_vm._s(_vm.language == 1 ? "联系方式" : _vm.language == 2 ? "IR Contact" : "聯繫方式"))])], 2), _c("el-menu-item", {
    attrs: {
      index: "7"
    }
  }, [_c("i", {
    staticClass: "el-icon-connection"
  }), _c("span", {
    attrs: {
      slot: "title"
    },
    on: {
      click: _vm.joinBtn
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.language == 1 ? "加入我们" : _vm.language == 2 ? "JOIN OUR TEAM" : "JOIN OUR TEAM"))])])], 1)], 1)], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };