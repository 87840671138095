export default {
  name: "SidebarItem",
  props: {
    menu: Array
  },

  mounted() {
    console.log(this.menu);
  }

};