import nxLangSelect from '@/components/nx-lang-select/index';
import ResizeMixin from './mixin/ResizeHandler';
import SideBarItem from "./components/sidebarItem.vue";
import { mapState } from 'vuex';
export default {
  name: "",

  data() {
    return {
      drawer: false,
      show_list: false,
      headerScroll: false,
      list: [{
        name: '关于我们',
        nameEn: 'ABOUT US',
        nameTr: '關於諾輝',
        path: '/about_company',
        children: [{
          name: '公司介绍',
          nameEn: 'About Company',
          nameTr: '公司介紹',
          path: '/about_company',
          value: 'about_company'
        }, {
          name: '发展历史',
          nameEn: 'History',
          nameTr: '發展歷史',
          path: '/about_company',
          value: 'development_history'
        }, {
          name: '管理团队',
          nameEn: 'Leadership',
          nameTr: '管理團隊',
          path: '/about_company',
          value: 'management_layer'
        }, {
          name: '荣誉资质',
          nameEn: 'Qualification Honor',
          nameTr: '榮譽資質',
          path: '/about_company',
          value: 'honors_qualifications'
        }, {
          name: '联系我们',
          nameEn: 'Contact Us',
          nameTr: '聯繫我們',
          path: '/about_company',
          value: 'contact_us'
        }, {
          name: '社会监督',
          nameEn: 'Social Hupervision',
          nameTr: '社會監督',
          path: '/about_company',
          value: 'honest'
        }]
      }, {
        name: '产品体系',
        nameEn: 'OUR TESTS',
        nameTr: '產品體系',
        path: '/product',
        children: [{
          name: '常卫清®',
          nameEn: 'ColoClear®',
          nameTr: '常衛清®',
          path: '/product_detail',
          value: 'product_detail'
        }, {
          name: '噗噗管®',
          nameEn: 'Pupu Tube®',
          nameTr: '噗噗管®',
          path: '/product_detail',
          value: 'product_detail'
        }, {
          name: '幽幽管®',
          nameEn: 'UU Tube®',
          nameTr: '幽幽管®',
          path: '/product_detail',
          value: 'product_detail'
        }, {
          name: '宫证清™(在研)',
          nameEn: 'CerviClear®(Pipeline)',
          nameTr: '宫证清™(在研)',
          path: '/product_detail',
          value: 'product_detail'
        }, {
          name: '中国泛癌种早筛早诊PANDA项目(在研)',
          nameEn: 'China MCED PANDA Project(Pipeline)',
          nameTr: '中國泛癌種早篩早診PANDA項目(在研)',
          path: '/product_detail',
          value: 'product_detail'
        }]
      }, {
        name: '用户故事',
        nameEn: 'PATIENT STORIES',
        nameTr: '用戶故事',
        path: '/user_story',
        value: 'user_story'
      }, {
        name: '新闻中心',
        nameEn: 'NEWS ROOM',
        nameTr: '新聞中心',
        path: '/news_activity',
        value: 'news_activity'
      }, {
        name: '文化与人才',
        nameEn: 'Culture and talent',
        nameTr: '文化與人才',
        path: '/corporate_culture',
        value: 'corporate_culture'
      }, {
        name: '投资者关系',
        nameEn: 'INVESTORS RELATIONS',
        nameTr: '投資者關係',
        // path: '/companyInformation',
        // value: 'companyInformation',
        children: [// {
        // 	name: '公司资料',
        // 	nameEn: 'Corporate Information',
        // 	nameTr: '公司資料',
        // 	path: '/companyInformation',
        // 	value: 'companyInformation',
        // },
        {
          name: '投资者日历',
          nameEn: 'Events Calendar',
          nameTr: '活動日曆',
          path: '/event',
          value: 'event'
        }, {
          name: '投资者月报',
          nameEn: 'Monthly Report',
          nameTr: '投資者月報',
          path: '/monthlyReport',
          value: 'monthlyReport'
        }, {
          name: '招股文件',
          nameEn: 'Listing Documents',
          nameTr: '招股文件',
          path: '/offering',
          value: 'offering'
        }, {
          name: '公告及通函',
          nameEn: 'Announcements & Notices',
          nameTr: '公告及通函',
          path: '/notice',
          value: 'notice'
        }, {
          name: '可持续发展',
          nameEn: 'ESG',
          nameTr: '可持續發展',
          path: '/esg',
          value: 'esg'
        }, {
          name: '企业管治',
          nameEn: 'Corporate Governance',
          nameTr: '企業管治',
          path: '/governance',
          value: 'governance'
        }, {
          name: '股票信息',
          nameEn: 'Stock Information',
          nameTr: '股票資訊',
          path: '/stock',
          value: 'stock'
        }, {
          name: '联系方式',
          nameEn: 'IR Contact',
          nameTr: '聯繫方式',
          path: '/contact',
          value: 'contact'
        }]
      }, {
        name: '加入我们',
        nameEn: 'JOIN OUR TEAM',
        nameTr: '加入我們',
        path: '/wx_recruit',
        value: 'wx_recruit'
      }],
      windowLocation: ''
    };
  },

  mixins: [ResizeMixin],
  components: {
    nxLangSelect,
    SideBarItem
  },
  computed: {
    indexMenu() {
      const route = this.$route;
      return route.path;
      console.log(route.path);
    },

    activeMenu() {
      const route = this.$route;
      return route.name;
    },

    queryMenu() {
      const route = this.$route;
      console.log(route.query.productName);
      return route.query.productName;
    },

    ...mapState({
      device: state => state.device,
      language: state => state.language
    })
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  created() {
    // this.queryMenu()
    console.log(window.location);
    this.windowLocation = window.location.origin;
  },

  methods: {
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop > 200) {
        this.headerScroll = true;
      }

      if (scrollTop < 100) {
        this.headerScroll = false;
      }
    },

    setLanguage(language) {
      this.$store.commit("setLanguage", language);
      console.log(this.$route.path);

      if (this.$route.path == "/articleDetail") {
        this.$router.go(-1);
      } else {
        // this.$router.go(0)
        location.reload();
      }
    },

    indexClick() {
      this.$router.push({
        path: "/index"
      });
    },

    changeRoute(item) {
      const path = item.path || item.children[0].path;
      if (path === this.$route.path) return;

      if (item.value == 'wx_recruit') {
        let href = 'https://app.mokahr.com/su/sygheq'; // let href = 'https://app.mokahr.com/social-recruitment/nhbio/100259'

        window.open(href, '_blank');
        return;
      }

      this.$router.push({
        path: path
      });
    },

    changeRouteChild(parent, child) {
      console.log('🚀 ~ child', child);

      if (child.value == 'product_detail') {
        // this.$router.push({
        // 		path: child.path,
        // 		query: {
        // 			productName: child.name
        // 		}
        // 	},
        // 	onComplete => {
        // 		this.show_list = !this.show_list
        // 	}
        // )
        return `${window.location.origin}/#${child.path}?productName=${child.name}`;
      } else if (child.value == 'wx_recruit') {
        let href = 'https://app.mokahr.com/social-recruitment/nhbio/100259';
        return 'https://app.mokahr.com/social-recruitment/nhbio/100259'; // let href = 'https://app.mokahr.com/social-recruitment/nhbio/42077'
        // window.open(href, '_blank')
      } else if (child.path == '/about_company') {
        // this.$router.push({
        // 		path: child.path,
        // 		query: {
        // 			hash: child.value
        // 		}
        // 	},
        // 	onComplete => {
        // 		this.show_list = !this.show_list
        // 	}
        // )
        // const el = document.getElementById(child.value)
        // el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        return `${window.location.origin}/#${child.path}?hash=${child.value}`;
      } else {
        // this.$router.push({
        // 		path: child.path,
        // 	},
        // 	onComplete => {
        // 		this.show_list = !this.show_list
        // 	}
        // )	
        return `${window.location.origin}/#${child.path}`;
      }
    },

    changeList(e) {
      e.preventDefault();
      this.show_list = !this.show_list;
      return false;
    },

    mobileChangeRoute(path, name) {
      console.log('🚀 ~ name', name);
      this.drawer = false;

      if (path == '/product_detail') {
        this.$router.push({
          path: path,
          query: {
            productName: name
          }
        });
        return;
      }

      if (path == '/about_company') {
        this.$router.push({
          path: path,
          query: {
            hash: name
          }
        });
        const el = document.getElementById(name);
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start'
        });
      }

      if (path === this.$route.path) return;
      this.$router.push({
        path: path
      });
    },

    joinBtn() {
      this.drawer = false;
      let href = 'https://app.mokahr.com/social-recruitment/nhbio/100259'; // let href = 'https://app.mokahr.com/social-recruitment/nhbio/42077'

      window.open(href, '_blank');
    }

  }
};